// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutyou-js": () => import("./../../../src/pages/aboutyou.js" /* webpackChunkName: "component---src-pages-aboutyou-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourscheme-js": () => import("./../../../src/pages/ourscheme.js" /* webpackChunkName: "component---src-pages-ourscheme-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

